@import "./colors.scss";

.row-lastopen {
	background-color: $color-lastopen !important;
	color: white !important;
}

.open-eye-column {
	flex-grow: 0 !important;
	flex-basis: 40px !important;
}

.companies-dataview {

	// ID
	.col_01 {
		flex-grow: 0 !important;
		flex-basis: 100px !important;
	}

	// Name
	.col_02 {
		flex-grow: 1 !important;
		flex-basis: 250px !important;
	}

	// Responsible
	.col_03 {
		flex-grow: 0 !important;
		flex-basis: 230px !important;
	}

	// Employees
	.col_04 {
		flex-grow: 0 !important;
		flex-basis: 110px !important;
	}

	// SbM
	.col_05 {
		flex-grow: 0 !important;
		flex-basis: 60px !important;
	}

	// Quote
	.col_06 {
		flex-grow: 0 !important;
		flex-basis: 75px !important;
	}

	td.col_06 {
		padding-left: 5px !important;
		padding-right: 5px !important;
	}

	// Anlagedatum
	.col_07 {
		flex-grow: 0 !important;
		flex-basis: 170px !important;
	}

	// Region
	.col_08 {
		flex-grow: 0 !important;
		flex-basis: 165px !important;
	}

	td.col_08 {
		overflow-x: hidden;
	}

	// Status
	.col_09 {
		flex-grow: 0 !important;
		flex-basis: 170px !important;
		max-width: 170px !important;
	}
}

.eaacases-dataview {

	// ID
	.col_01 {
		flex-grow: 0 !important;
		flex-basis: 100px !important;
	}

	// Responsible
	.col_02 {
		flex-grow: 0 !important;
		flex-basis: 230px !important;
	}

	// Company Name
	.col_03 {
		flex-grow: 1 !important;
		flex-basis: 250px !important;
	}

	// Name
	.col_04 {
		flex-grow: 1 !important;
		flex-basis: 250px !important;
	}

	// Anlagedatum
	.col_05 {
		flex-grow: 0 !important;
		flex-basis: 170px !important;
	}

	// Status
	.col_06 {
		flex-grow: 0 !important;
		flex-basis: 200px !important;
	}
}